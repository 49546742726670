import React from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../layout/layout'
import Search from '../components/search'

const SearchResults = props => {
  const query = props.location.search.slice(1)
  const searchStore = props.data.localSearchNotesIndex

  const index = searchStore.index
  const store = searchStore.store
  const results = useFlexSearch(query, index, store)

  return (
    <Layout title={'Search results'}>
      <div className="column is-half">
        {results.length === 0 ? (
          <>
            <h1>
              ❓ Sorry, I could not find anything for the search term "{decodeURIComponent(query)}"
            </h1>
            <br />
            <Search size="medium" showExcerpt={true} />
          </>
        ) : (
          <>
            <h1>🔍 Here's what I could find for your search term "{decodeURIComponent(query)}"</h1>
            <div className="search-result">
              <ul>
                {results.map((result, i) => (
                  <div>
                    <Link to={result.slug}>{result.title}</Link>
                  </div>
                ))}
              </ul>
            </div>
            <p>
              ❓ Could not find what you are looking for? Try searching here
            </p>
            <Search size="medium" showExcerpt={true} />
          </>
        )}
      </div>
    </Layout>
  )
}

export default SearchResults

export const query = graphql`
  query {
    localSearchNotesIndex {
      index
      store
    }
  }
`
